import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import type { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { routesUrl } from '@/routes/routesUrl';
import { ChevronDownIcon } from '@/ui/icon/ChevronDownIcon';
import { ChevronRightIcon } from '@/ui/icon/ChevronRightIcon';
import { CloseIcon } from '@/ui/icon/CloseIcon';
import { HamburgerIcon } from '@/ui/icon/HamburgerIcon';

import { DocumentIcon } from '../icon/DocumentIcon';
import { LegalIcon } from '../icon/LegalIcon';
import { PromoIcon } from '../icon/PromoIcon';
import { UserGroupIcon } from '../icon/UserGroupIcon';

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  icon?: ComponentWithAs<'svg', IconProps>;
}

export function Navbar() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation(['common']);

  const navItems: Array<NavItem> = [
    {
      label: t('common:navbar.nav_item.service'),
      icon: LegalIcon,
      href: routesUrl.service,
    },
    {
      label: t('common:navbar.nav_item.promo'),
      icon: PromoIcon,
      href: routesUrl.promo,
    },
    {
      label: t('common:navbar.nav_item.about_us'),
      icon: UserGroupIcon,
      href: routesUrl.aboutUs,
    },
    {
      label: t('common:navbar.nav_item.article'),
      icon: DocumentIcon,
      href: routesUrl.article,
    },
  ];

  const mobileNavFooterItems: Array<NavItem> = [
    {
      label: t('common:navbar.mobile_footer_item.tnc'),
      href: routesUrl.termsCondition,
    },
    {
      label: t('common:navbar.mobile_footer_item.faq'),
      href: routesUrl.faq,
    },
    {
      label: t('common:navbar.mobile_footer_item.privacy'),
      href: routesUrl.privacyPolicy,
    },
  ];

  return (
    <Box zIndex='docked'>
      <Flex
        justifyContent={{ base: 'space-between' }}
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        h={{ base: '56px', md: '72px' }}
        py={{ base: '12px', md: '16px' }}
        px={{ base: '24px', md: '140px' }}
        borderBottom={1}
        borderStyle='solid'
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align='center'>
        <Flex justify='start' flex={{ base: 'auto', md: '1' }}>
          <Link to='/'>
            <Image src='/images/logo.png' h={{ base: '32px', md: '40px' }} />
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} ml='auto'>
            <DesktopNav navItems={navItems} />
            <Link to={routesUrl.consultation} style={{ marginLeft: '48px' }}>
              <Button
                display={{ base: 'none', md: 'inline-flex' }}
                fontSize='sm'
                variant='secondary'>
                {t('common:navbar.cta_button_label')}
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Flex ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={<HamburgerIcon w={6} h={6} color='brandPrimary.900' />}
            variant='ghost'
            aria-label='Toggle Navigation'
          />
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='full'>
        <DrawerOverlay />
        <DrawerContent bgColor='brandPrimary.900'>
          <DrawerHeader
            h='56px'
            display='flex'
            justifyContent='space-between'
            alignItems='center'>
            <Link to='/'>
              <Image src='/images/logo-white.png' h='32px' />
            </Link>
            <IconButton
              onClick={onClose}
              icon={<CloseIcon w={6} h={6} color='white' />}
              variant='ghost'
              aria-label='Close mobile navbar'
            />
          </DrawerHeader>
          <Divider
            size='1px'
            mb='24px'
            borderColor='brandSecondary.700'
            opacity='1'
          />
          <DrawerBody pt='0'>
            <MobileNav navItems={navItems} footerItems={mobileNavFooterItems} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

type DesktopNavProps = {
  navItems: Array<NavItem>;
};

function DesktopNav({ navItems }: DesktopNavProps) {
  const location = useLocation();

  return (
    <Stack direction='row' spacing='32px'>
      {navItems.map((navItem) => {
        const isActive = location.pathname.startsWith(navItem.href ?? '#');

        return (
          <Flex key={navItem.label}>
            <Popover trigger='hover' placement='bottom-start'>
              <PopoverTrigger>
                <Link to={navItem.href ?? '#'} style={{ display: 'flex' }}>
                  <Flex
                    position='relative'
                    flexDir='column'
                    justifyContent='center'
                    alignItems='center'
                    color={isActive ? 'brandSecondary.700' : 'brandPrimary.900'}
                    _hover={{
                      color: 'brandSecondary.700',
                    }}>
                    <Text fontSize='sm' fontWeight={500}>
                      {navItem.label}
                    </Text>
                    {isActive && (
                      <Box
                        position='absolute'
                        bottom='-16px'
                        w='100%'
                        h='4px'
                        bgColor='brandSecondary.700'
                        borderTopRightRadius='4px'
                        borderTopLeftRadius='4px'
                      />
                    )}
                  </Flex>
                </Link>
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow='xl'
                  bg='gray.800'
                  p={4}
                  rounded='xl'
                  minW='sm'>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Flex>
        );
      })}
    </Stack>
  );
}

function DesktopSubNav({ label, href, subLabel }: NavItem) {
  return (
    <Link to={href ?? '#'} role='group'>
      <Stack direction='row' align='center'>
        <Box>
          <Text
            transition='all .3s ease'
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize='sm'>{subLabel}</Text>
        </Box>
        <Flex
          transition='all .3s ease'
          transform='translateX(-10px)'
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify='flex-end'
          align='center'
          flex={1}>
          <Icon color='pink.400' w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
}

type MobileNavItemProps = {
  navItems: Array<NavItem>;
  footerItems: Array<NavItem>;
};

function MobileNav({ navItems, footerItems }: MobileNavItemProps) {
  const { t } = useTranslation(['common']);
  return (
    <Flex flexDir='column' display={{ md: 'none' }}>
      <Stack>
        {navItems.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
      <Link to={routesUrl.consultation}>
        <Button size='lg' mt='16px' color='white' w='100%' variant='secondary'>
          {t('common:navbar.cta_button_label')}
        </Button>
      </Link>
      <Divider
        size='1px'
        my='24px'
        borderColor='brandSecondary.700'
        opacity='1'
        ml='-24px'
        w='100vw'
      />
      <Stack>
        {footerItems.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    </Flex>
  );
}

function MobileNavItem({ label, children, href, icon }: NavItem) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        to={href ?? '#'}
        justify='space-between'
        align='center'
        _hover={{
          textDecoration: 'none',
        }}>
        <HStack spacing='8px'>
          {icon && <Icon as={icon} w={6} h={6} color='brandSecondary.700' />}
          <Text fontWeight={600} color='brandSecondary.50'>
            {label}
          </Text>
        </HStack>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition='all .25s ease-in-out'
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse
        in={isOpen}
        animateOpacity={true}
        style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle='solid'
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align='start'>
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                to={child.href ?? '#'}
                style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}
