import { createIcon } from '@chakra-ui/react';

export const HamburgerIcon = createIcon({
  displayName: 'HamburgerIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M3 6.00003C3 5.57693 3.31232 5.22742 3.7156 5.17615L3.81818 5.16968H20.1818L20.2844 5.17615C20.6877 5.22742 21 5.57693 21 6.00003C21 6.45839 20.6335 6.83038 20.1818 6.83038H3.81818C3.36655 6.83038 3 6.45839 3 6.00003Z'
        fill='currentColor'
      />
      <path
        d='M3 12C3 11.5769 3.31232 11.2274 3.7156 11.1762L3.81818 11.1697H20.1818L20.2844 11.1762C20.6877 11.2274 21 11.5769 21 12C21 12.4584 20.6335 12.8304 20.1818 12.8304H3.81818C3.36655 12.8304 3 12.4584 3 12Z'
        fill='currentColor'
      />
      <path
        d='M3 18C3 17.5769 3.31232 17.2274 3.7156 17.1762L3.81818 17.1697H20.1818L20.2844 17.1762C20.6877 17.2274 21 17.5769 21 18C21 18.4584 20.6335 18.8304 20.1818 18.8304H3.81818C3.36655 18.8304 3 18.4584 3 18Z'
        fill='currentColor'
      />
    </>
  ),
});
