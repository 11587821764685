import { createIcon } from '@chakra-ui/react';

export const DocumentIcon = createIcon({
  displayName: 'DocumentIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M15.8534 2C19.0668 2 20.9913 3.95143 21 7.20971V16.7903C21 20.0486 19.0842 22 15.8621 22H13.0058C12.6395 21.959 12.3622 21.6452 12.3622 21.2715C12.3622 20.8978 12.6395 20.584 13.0058 20.543H15.8534C18.3091 20.543 19.5457 19.2804 19.5457 16.7903V7.20971C19.5457 4.71965 18.3091 3.45695 15.8534 3.45695H8.13788C5.68215 3.45695 4.43686 4.71965 4.43686 7.20971V16.7903C4.43686 19.2804 5.68215 20.543 8.13788 20.543C8.50419 20.584 8.78145 20.8978 8.78145 21.2715C8.78145 21.6452 8.50419 21.959 8.13788 22C4.92453 22 3 20.0486 3 16.7903V7.20971C3 3.9426 4.92453 2 8.13788 2H15.8534ZM15.5922 15.5099C15.9865 15.5099 16.3062 15.8341 16.3062 16.234C16.3062 16.6339 15.9865 16.9581 15.5922 16.9581H8.38171C7.98734 16.9581 7.66763 16.6339 7.66763 16.234C7.66763 15.8341 7.98734 15.5099 8.38171 15.5099H15.5922ZM15.5922 11.2715C15.8677 11.2407 16.1364 11.3724 16.2837 11.6106C16.431 11.8487 16.431 12.1513 16.2837 12.3894C16.1364 12.6276 15.8677 12.7593 15.5922 12.7285H8.38171C8.01541 12.6875 7.73814 12.3737 7.73814 12C7.73814 11.6263 8.01541 11.3125 8.38171 11.2715H15.5922ZM11.1335 7.04194C11.4998 7.0829 11.7771 7.39674 11.7771 7.77042C11.7771 8.1441 11.4998 8.45794 11.1335 8.4989H8.39042C8.02411 8.45794 7.74685 8.1441 7.74685 7.77042C7.74685 7.39674 8.02411 7.0829 8.39042 7.04194H11.1335Z'
      fill='currentColor'
    />
  ),
});
