import { createIcon } from '@chakra-ui/react';

export const InstagramOutlineIcon = createIcon({
  displayName: 'InstagramOutlineIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M17.6298 7.16251C18.0744 7.16251 18.4349 6.80204 18.4349 6.35738C18.4349 5.91272 18.0744 5.55225 17.6298 5.55225C17.1851 5.55225 16.8246 5.91272 16.8246 6.35738C16.8246 6.80204 17.1851 7.16251 17.6298 7.16251Z'
        fill='currentColor'
      />
      <path
        d='M15.5768 9.83545C15.4777 9.87372 15.3872 9.93122 15.3104 10.0046C15.2336 10.0781 15.1721 10.1659 15.1295 10.2632C15.0868 10.3605 15.0638 10.4653 15.0618 10.5715C15.0599 10.6777 15.0789 10.7833 15.1179 10.8821C15.3832 11.5704 15.4042 12.3289 15.1773 13.0308C14.9505 13.7326 14.4896 14.3354 13.8717 14.7382C13.2538 15.141 12.5163 15.3196 11.7826 15.2439C11.0488 15.1682 10.3633 14.843 9.84058 14.3225C9.31787 13.8021 8.98965 13.118 8.91081 12.3846C8.83197 11.6512 9.00728 10.9129 9.40743 10.2933C9.80757 9.67363 10.4083 9.21011 11.1092 8.98023C11.8101 8.75035 12.5687 8.76803 13.2581 9.03032C13.4577 9.10613 13.6793 9.09951 13.8741 9.01194C14.0689 8.92437 14.2209 8.763 14.2967 8.56335C14.3725 8.36369 14.3659 8.1421 14.2783 7.94732C14.1907 7.75255 14.0294 7.60053 13.8297 7.52473C12.7968 7.13403 11.6613 7.109 10.6122 7.45381C9.5631 7.79863 8.66382 8.49247 8.06414 9.41976C7.46446 10.347 7.20059 11.4518 7.31648 12.55C7.43236 13.6482 7.92101 14.6736 8.70103 15.4553C9.60681 16.3599 10.8346 16.868 12.1148 16.868C13.3949 16.868 14.6228 16.3599 15.5285 15.4553C16.1918 14.7938 16.6484 13.9538 16.8428 13.0375C17.0372 12.1212 16.961 11.1682 16.6235 10.2944C16.5853 10.1953 16.5278 10.1047 16.4543 10.0279C16.3809 9.95115 16.293 9.88966 16.1957 9.847C16.0984 9.80434 15.9937 9.78136 15.8874 9.77938C15.7812 9.77739 15.6757 9.79645 15.5768 9.83545Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5049 3.53898C21.2426 4.29166 21.6556 5.30359 21.6554 6.35745C21.9614 10.13 21.9614 13.9211 21.6554 17.6937C21.6387 18.7432 21.2126 19.7447 20.4682 20.4847C19.7237 21.2247 18.7196 21.6446 17.67 21.6549C15.786 21.8401 13.902 21.9287 11.9938 21.9287C10.0857 21.9287 8.17752 21.8321 6.27741 21.6549C5.22375 21.6339 4.22038 21.2004 3.48275 20.4478C2.74512 19.6951 2.33206 18.6831 2.33227 17.6293C2.0424 13.8561 2.0424 10.0662 2.33227 6.29304C2.34917 5.23658 2.78075 4.22911 3.53389 3.48803C4.28702 2.74694 5.30132 2.33167 6.35792 2.3318C10.1341 1.9851 13.9341 1.9851 17.7103 2.3318C18.7639 2.35288 19.7673 2.7863 20.5049 3.53898ZM19.3377 19.3372C19.7907 18.8842 20.0451 18.2699 20.0451 17.6293C20.3511 13.8997 20.3511 10.1514 20.0451 6.42186C20.0537 6.09929 19.9976 5.77827 19.8801 5.47774C19.7626 5.17722 19.586 4.90329 19.3609 4.67213C19.1357 4.44098 18.8665 4.25728 18.5692 4.13189C18.2719 4.00649 17.9524 3.94195 17.6297 3.94206C15.7243 3.76493 13.8429 3.67905 11.9858 3.68442C10.1286 3.68979 8.27681 3.77567 6.43038 3.94206C6.10714 3.93236 5.78525 3.98767 5.48378 4.1047C5.18232 4.22173 4.90743 4.39811 4.6754 4.62338C4.44338 4.84864 4.25896 5.1182 4.13307 5.41607C4.00718 5.71395 3.94238 6.03407 3.94253 6.35745C3.66073 10.0879 3.66073 13.8344 3.94253 17.5649C3.93392 17.8874 3.99004 18.2085 4.10757 18.509C4.22509 18.8095 4.40165 19.0834 4.6268 19.3146C4.85195 19.5458 5.12114 19.7295 5.41847 19.8549C5.71579 19.9802 6.03523 20.0448 6.35792 20.0447C10.0796 20.407 13.8276 20.407 17.5492 20.0447H17.6297C18.2704 20.0447 18.8847 19.7902 19.3377 19.3372Z'
        fill='currentColor'
      />
    </>
  ),
});
