import { createIcon } from '@chakra-ui/react';

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M7.744 19.7583C7.44646 19.4636 7.41979 19.001 7.67086 18.6783L7.74623 18.594L15.0723 11.4174C15.4002 11.0959 15.93 11.097 16.2557 11.4196C16.4175 11.5798 16.5 11.7905 16.5 12.0001C16.5 12.1766 16.4427 12.3523 16.3287 12.4987L16.2535 12.5828L8.92745 19.7594C8.59952 20.0809 8.0697 20.0798 7.744 19.7583ZM7.74634 5.40707C7.41729 5.08555 7.41952 4.56431 7.74411 4.24279C8.04267 3.94706 8.51275 3.92149 8.84161 4.16609L8.92756 4.2395L13.793 9.00525C13.957 9.16546 14.0395 9.37725 14.0395 9.58794C14.0395 9.79753 13.957 10.0082 13.7952 10.1684C13.4967 10.4642 13.0266 10.4897 12.6977 10.2451L12.6118 10.1717L7.74634 5.40707Z'
      fill='currentColor'
    />
  ),
});
