import { createIcon } from '@chakra-ui/react';

export const PhoneIcon = createIcon({
  displayName: 'PhoneIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M4.30629 10.5849C4.6483 10.353 5.11531 10.4369 5.34931 10.7778C7.94438 14.5448 12.6845 18.7856 15.7006 20.039C16.9206 20.6057 17.8286 20.6407 18.5047 20.1559C19.0317 19.8371 20.5287 18.3499 20.5017 17.5763C20.4927 17.4853 20.3887 17.3144 20.2117 17.1335C19.6357 16.5438 17.7026 15.0815 17.2986 14.9296C16.9796 14.8617 16.6476 15.0556 16.1416 15.3744L15.8856 15.5333C15.5306 15.7462 15.0696 15.6333 14.8556 15.2774C14.6426 14.9236 14.7566 14.4629 15.1116 14.249L15.3426 14.106C15.9026 13.7532 16.6756 13.2715 17.6476 13.4734C18.4136 13.6323 20.8227 15.6133 21.2837 16.084C21.7177 16.5278 21.9577 17.0006 21.9987 17.4873C22.0637 19.3294 19.5967 21.2544 19.3137 21.4163C18.7757 21.8041 18.1446 22 17.4346 22C16.7286 22 15.9446 21.8061 15.0956 21.4103C11.8305 20.055 6.89235 15.6603 4.11428 11.6273C3.87928 11.2865 3.96528 10.8197 4.30629 10.5849ZM6.30267 2.00098L6.47944 2.00375C6.99546 2.04673 7.46747 2.28661 7.91048 2.71838C8.38249 3.17915 10.3645 5.58991 10.5235 6.35252C10.6946 7.1731 10.3255 7.81377 10.0565 8.28153C9.79153 8.7423 9.69953 8.9392 9.79053 9.18807C10.4595 10.8242 11.4936 12.1615 12.8546 13.133C13.1916 13.3749 13.2686 13.8427 13.0276 14.1795C12.8816 14.3834 12.6516 14.4933 12.4176 14.4933C12.2656 14.4933 12.1136 14.4474 11.9816 14.3534C10.3875 13.213 9.17951 11.6568 8.39049 9.72679C8.04348 8.77228 8.45549 8.05665 8.7565 7.53392C8.98251 7.14212 9.10751 6.90824 9.05451 6.65836C8.9145 6.29755 7.45247 4.36754 6.86245 3.79283C6.68045 3.61392 6.50944 3.50898 6.38244 3.49898C5.66642 3.48199 4.20238 4.89727 3.87038 5.44798C3.35536 6.15562 3.39136 7.06216 3.94438 8.27653C4.11538 8.65334 3.94938 9.09711 3.57237 9.26903C3.19436 9.43994 2.75135 9.27502 2.57934 8.89722C1.79232 7.1711 1.80732 5.72984 2.62334 4.61741C2.92735 4.10867 4.8384 1.8968 6.47944 2.00375L6.30267 2.00098Z'
      fill='currentColor'
    />
  ),
});
