import { createIcon } from '@chakra-ui/react';

export const FacebookOutlineIcon = createIcon({
  displayName: 'FacebookOutlineIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M22.0867 10.9572C22.4467 10.8897 22.6867 10.5447 22.6192 10.1772C21.7267 5.31725 17.5042 1.76975 12.4942 1.73975C5.98424 1.91975 2.64674 6.81725 2.30174 11.2872C1.94924 15.8922 4.46924 21.0672 10.8967 22.2522C10.9342 22.2597 10.9717 22.2597 11.0092 22.2597C11.1592 22.2597 11.3167 22.1997 11.4367 22.0947C11.5867 21.9672 11.6767 21.7797 11.6692 21.5922V14.8497C11.6692 14.4822 11.3692 14.1822 11.0017 14.1822H9.23174V12.7272H11.0017C11.3692 12.7272 11.6692 12.4272 11.6692 12.0597V9.92975C11.6692 9.75725 11.6017 9.58475 11.4742 9.45725C11.3467 9.33725 11.1817 9.26225 11.0017 9.26225C10.6417 9.26225 10.3417 9.56225 10.3417 9.92975V11.3922H8.56424C8.19674 11.3922 7.89674 11.6922 7.89674 12.0597V14.8497C7.89674 15.2172 8.19674 15.5172 8.56424 15.5172H10.3417V20.7672C5.21924 19.4247 3.27674 15.0597 3.63674 11.2272C4.01924 7.27475 6.90674 3.21725 12.5092 3.07475C16.3642 3.07475 19.7767 5.53475 20.9992 9.19475C21.1267 9.59225 21.2392 10.0047 21.3142 10.4247C21.3817 10.7847 21.7267 11.0172 22.0867 10.9572Z'
        fill='currentColor'
      />
      <path
        d='M13.8442 22.1849C18.3667 21.8999 22.1842 18.0449 22.7242 13.2299C22.7467 13.0574 22.6867 12.8774 22.5742 12.7424C22.4392 12.5999 22.2592 12.5174 22.0642 12.5174C21.7117 12.5174 21.4417 12.7724 21.3967 13.1399C20.9617 16.8749 18.2842 19.8899 14.6767 20.7824V15.5174H16.2517C16.5817 15.5174 16.8592 15.2774 16.9117 14.9474L17.3317 12.1649C17.3692 11.9774 17.3092 11.7749 17.1817 11.6249C17.0467 11.4674 16.8742 11.3924 16.6792 11.3924H14.6767V10.2524C14.6767 9.95239 14.7367 9.74239 14.8642 9.62239C15.1117 9.37489 15.6742 9.38989 16.2067 9.40489C16.4092 9.41239 16.6117 9.41239 16.7917 9.40489C17.1592 9.40489 17.4592 9.10489 17.4592 8.73739V6.36739C17.4592 6.03739 17.2267 5.75989 16.9042 5.70739C15.8767 5.52739 12.7417 4.98739 11.1967 7.04239C10.9267 7.40239 11.0317 7.78489 11.2717 7.97239C11.5342 8.17489 11.9842 8.17489 12.3142 7.77739C13.1842 6.71989 14.8342 6.75739 16.1242 6.93739V8.07739H15.5767C14.2042 8.07739 13.3492 8.90989 13.3492 10.2449V12.0599C13.3492 12.4274 13.6417 12.7274 14.0092 12.7274H15.9067L15.6817 14.1824H14.0092C13.6417 14.1824 13.3492 14.4824 13.3492 14.8499V21.5924C13.3492 21.7874 13.4317 21.9749 13.5817 22.1024L13.6942 22.1999L13.8442 22.1849Z'
        fill='currentColor'
      />
    </>
  ),
});
