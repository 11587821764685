import { createIcon } from '@chakra-ui/react';

export const LinkedinOutlineIcon = createIcon({
  displayName: 'LinkedinOutlineIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.11487 4.47626C2.11487 2.92434 3.37295 1.66626 4.92487 1.66626C6.47679 1.66626 7.73488 2.92434 7.73488 4.47626C7.73488 6.02818 6.47679 7.28627 4.92487 7.28627C3.37295 7.28627 2.11487 6.02819 2.11487 4.47626ZM4.92487 2.73068C3.96082 2.73068 3.17929 3.51221 3.17929 4.47626C3.17929 5.44032 3.96082 6.22184 4.92487 6.22184C5.88893 6.22184 6.67045 5.44032 6.67045 4.47626C6.67045 3.51221 5.88893 2.73068 4.92487 2.73068Z'
        fill='currentColor'
      />
      <path
        d='M14.5224 8.91323C13.9397 9.22857 13.3733 9.8411 12.9547 11.129C12.8738 11.378 12.6238 11.5312 12.3653 11.4902C12.1067 11.4492 11.9164 11.2263 11.9164 10.9645V9.44601C11.9164 9.25677 11.7405 9.08091 11.5513 9.08091H9.61858C9.42935 9.08091 9.25348 9.25677 9.25348 9.44601V20.835C9.25348 21.0242 9.42935 21.2001 9.61858 21.2001H11.5513C11.7405 21.2001 11.9164 21.0242 11.9164 20.835C11.9164 20.8286 11.9165 20.8221 11.9167 20.8157L12.1234 15.0962C12.1251 13.6993 12.4949 12.6912 13.1243 12.0299C13.7558 11.3662 14.5816 11.1226 15.3476 11.1226C16.1595 11.1226 16.8942 11.4367 17.3933 12.224C17.8626 12.9645 18.0886 14.0708 18.0886 15.5891V20.904C18.0886 21.0932 18.2644 21.2691 18.4536 21.2691C18.7476 21.2691 18.9859 21.5074 18.9859 21.8013C18.9859 22.0952 18.7476 22.3335 18.4536 22.3335C17.6766 22.3335 17.0241 21.6811 17.0241 20.904V15.5891C17.0241 14.1394 16.8014 13.2785 16.4942 12.7939C16.2167 12.3561 15.8471 12.187 15.3476 12.187C14.8021 12.187 14.2819 12.3575 13.8954 12.7637C13.508 13.1707 13.1878 13.885 13.1878 15.106C13.1878 15.1124 13.1877 15.1188 13.1875 15.1252L12.9807 20.8457C12.9747 21.6181 12.3248 22.2645 11.5513 22.2645H9.61858C8.84148 22.2645 8.18906 21.6121 8.18906 20.835V9.44601C8.18906 8.66891 8.84148 8.01648 9.61858 8.01648H11.5513C12.1512 8.01648 12.6768 8.4053 12.8853 8.94013C13.2193 8.51678 13.5982 8.20309 14.0158 7.97709C14.876 7.51153 15.8235 7.46429 16.659 7.46429C17.5647 7.46429 18.853 7.70615 19.9312 8.45708C21.0377 9.22767 21.8849 10.511 21.8849 12.4831V20.835C21.8849 21.1289 21.6466 21.3672 21.3527 21.3672C21.0587 21.3672 20.8204 21.1289 20.8204 20.835V12.4831C20.8204 10.8659 20.1491 9.90593 19.3229 9.33055C18.4685 8.73552 17.4099 8.52872 16.659 8.52872C15.838 8.52872 15.1289 8.58502 14.5224 8.91323Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.95862 8.01643C3.18152 8.01643 2.5291 8.66885 2.5291 9.44595V20.9039C2.5291 21.681 3.18152 22.3335 3.95862 22.3335H5.8913C6.69379 22.3335 7.32082 21.655 7.32082 20.8349V9.44595C7.32082 8.66885 6.6684 8.01643 5.8913 8.01643H3.95862ZM3.59353 9.44595C3.59353 9.25672 3.76939 9.08085 3.95862 9.08085H5.8913C6.08053 9.08085 6.25639 9.25672 6.25639 9.44595V20.8349C6.25639 21.1192 6.05514 21.269 5.8913 21.269H3.95862C3.76939 21.269 3.59353 21.0932 3.59353 20.9039V9.44595Z'
        fill='currentColor'
      />
    </>
  ),
});
