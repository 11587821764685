import {
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routesUrl } from '@/routes/routesUrl';

import { EmailIcon } from '../icon/EmailIcon';
import { FacebookOutlineIcon } from '../icon/FacebookOutlineIcon';
import { InstagramOutlineIcon } from '../icon/InstagramOutlineIcon';
import { LinkedinOutlineIcon } from '../icon/LinkedinOutlineIcon';
import { LocationIcon } from '../icon/LocationIcon';
import { PhoneIcon } from '../icon/PhoneIcon';
import { YoutubeOutlineIcon } from '../icon/YoutubeOutlineIcon';

const SOCIAL_LINKS = [
  {
    name: 'instagram',
    url: 'https://www.instagram.com/',
    icon: InstagramOutlineIcon,
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/',
    icon: LinkedinOutlineIcon,
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/',
    icon: FacebookOutlineIcon,
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/',
    icon: YoutubeOutlineIcon,
  },
];

function Footer() {
  const { t } = useTranslation(['common']);
  const isMobile = useBreakpointValue({ base: true, md: false }) ?? false;
  const navigations = [
    {
      text: t('common:footer.navigation.item.service'),
      href: routesUrl.service,
    },
    {
      text: t('common:footer.navigation.item.about_us'),
      href: routesUrl.aboutUs,
    },
    {
      text: t('common:footer.navigation.item.promo'),
      href: routesUrl.promo,
    },
    {
      text: t('common:footer.navigation.item.article'),
      href: routesUrl.article,
    },
    {
      text: t('common:footer.navigation.item.consultation'),
      href: routesUrl.consultation,
    },
  ];
  const informations = [
    {
      text: t('common:footer.information.item.tnc'),
      href: routesUrl.termsCondition,
    },
    {
      text: t('common:footer.information.item.faq'),
      href: routesUrl.faq,
    },
    {
      text: t('common:footer.information.item.privacy'),
      href: routesUrl.privacyPolicy,
    },
  ];
  const ourContacts = [
    { text: t('common:footer.our_contact.address'), icon: LocationIcon },
    { text: t('common:footer.our_contact.phone'), icon: PhoneIcon },
    { text: t('common:footer.our_contact.email'), icon: EmailIcon },
  ];

  return (
    <Flex
      borderTopWidth='8px'
      borderTopColor='brandSecondary.700'
      bgColor='brandPrimary.900'
      color='white'
      flexDir='column'>
      <Flex px={{ base: '24px', md: '140px' }}>
        <SimpleGrid
          columns={{ base: 1, md: 4 }}
          w='100%'
          mt={{ base: '40px', md: '64px' }}
          mb={{ base: '40px', md: '64px' }}>
          <Stack
            direction='column'
            justifyContent={{ base: 'center', md: 'flex-start' }}
            alignItems={{ base: 'center', md: 'flex-start' }}
            spacing='16px'>
            <Image
              src='/images/logo-white.png'
              height='40px'
              width='auto'
              objectFit='contain'
            />
            <Heading
              as='h2'
              fontSize={{ base: '18px', md: '21px' }}
              mt='16px'
              textAlign={{ base: 'center', md: 'left' }}>
              {t('common:footer.brand_name')}
            </Heading>
            <Stack direction='row' spacing='8px'>
              {SOCIAL_LINKS.map((socialLink, i) => {
                return (
                  <a
                    key={i}
                    href={socialLink.url}
                    target='_blank'
                    rel='noreferrer'>
                    <Flex
                      w='40px'
                      h='40px'
                      bgColor='brandSecondary.700'
                      borderRadius='100%'
                      _hover={{
                        bgColor: 'brandSecondary.600',
                      }}>
                      <Icon as={socialLink.icon} w='24px' h='24px' m='auto' />
                    </Flex>
                  </a>
                );
              })}
            </Stack>
          </Stack>
          {isMobile ? (
            <SimpleGrid mt={{ base: '40px', md: 0 }} columns={2}>
              <Flex flexDir='column'>
                <Heading as='h2' fontSize={{ base: '18px', md: '21px' }}>
                  {t('common:footer.navigation.title')}
                </Heading>
                <Stack direction='column' mt='16px'>
                  {navigations.map((item, i) => (
                    <Link key={i} to={item.href}>
                      <Text fontSize={{ base: '14px', md: '16px' }}>
                        {item.text}
                      </Text>
                    </Link>
                  ))}
                </Stack>
              </Flex>
              <Flex flexDir='column'>
                <Heading as='h2' fontSize={{ base: '18px', md: '21px' }}>
                  {t('common:footer.information.title')}
                </Heading>
                <Stack direction='column' mt='16px'>
                  {informations.map((item, i) => (
                    <Link key={i} to={item.href}>
                      <Text fontSize={{ base: '14px', md: '16px' }}>
                        {item.text}
                      </Text>
                    </Link>
                  ))}
                </Stack>
              </Flex>
            </SimpleGrid>
          ) : (
            <>
              <Flex flexDir='column' display={{ base: 'none', md: 'block' }}>
                <Heading as='h2' fontSize={{ base: '18px', md: '21px' }}>
                  {t('common:footer.navigation.title')}
                </Heading>
                <Stack direction='column' mt='16px'>
                  {navigations.map((item, i) => (
                    <Link key={i} to={item.href}>
                      <Text fontSize={{ base: '14px', md: '16px' }}>
                        {item.text}
                      </Text>
                    </Link>
                  ))}
                </Stack>
              </Flex>
              <Flex flexDir='column' display={{ base: 'none', md: 'block' }}>
                <Heading as='h2' fontSize={{ base: '18px', md: '21px' }}>
                  {t('common:footer.information.title')}
                </Heading>
                <Stack direction='column' mt='16px'>
                  {informations.map((item, i) => (
                    <Link key={i} to={item.href}>
                      <Text fontSize={{ base: '14px', md: '16px' }}>
                        {item.text}
                      </Text>
                    </Link>
                  ))}
                </Stack>
              </Flex>
            </>
          )}

          <Flex flexDir='column' mt={{ base: '40px', md: 0 }}>
            <Heading as='h2' fontSize={{ base: '18px', md: '21px' }}>
              {t('common:footer.our_contact.title')}
            </Heading>
            <Stack direction='column' mt='16px'>
              {ourContacts.map((item, i) => (
                <Stack key={i} direction='row' spacing='12px'>
                  <Icon
                    as={item.icon}
                    w='24px'
                    h='24px'
                    color='brandSecondary.700'
                  />
                  <Text fontSize={{ base: '14px', md: '16px' }}>
                    {item.text}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Flex>
        </SimpleGrid>
      </Flex>
      <Flex
        py='24px'
        px='24px'
        borderTopColor='brandSecondary.700'
        borderTopWidth='1px'
        justifyContent='center'>
        <Text textAlign='center' fontSize={{ base: '14px', md: '16px' }}>
          {t('common:footer.footer_text', { year: new Date().getFullYear() })}
        </Text>
      </Flex>
    </Flex>
  );
}

export { Footer };
