import { createIcon } from '@chakra-ui/react';

export const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M18.7571 5.24293C19.081 5.56683 19.081 6.09198 18.7571 6.41589L6.41589 18.7571C6.09198 19.081 5.56683 19.081 5.24293 18.7571C4.91902 18.4332 4.91902 17.908 5.24293 17.5841L17.5841 5.24293C17.908 4.91902 18.4332 4.91902 18.7571 5.24293Z'
        fill='currentColor'
      />
      <path
        d='M5.24293 5.24293C5.56683 4.91902 6.09198 4.91902 6.41589 5.24293L10.4162 9.24324C10.7401 9.56715 10.7401 10.0923 10.4162 10.4162C10.0923 10.7401 9.56715 10.7401 9.24324 10.4162L5.24293 6.41589C4.91902 6.09198 4.91902 5.56683 5.24293 5.24293Z'
        fill='currentColor'
      />
      <path
        d='M14.7608 13.5878C14.4369 13.2639 13.9117 13.2639 13.5878 13.5878C13.2639 13.9117 13.2639 14.4369 13.5878 14.7608L17.5841 18.7571C17.908 19.081 18.4332 19.081 18.7571 18.7571C19.081 18.4332 19.081 17.908 18.7571 17.5841L14.7608 13.5878Z'
        fill='currentColor'
      />
    </>
  ),
});
