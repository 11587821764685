const button = {
  baseStyle: {
    borderRadius: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  variants: {
    secondary: {
      bgColor: 'brandSecondary.700',
      color: 'white',
      _hover: {
        bg: 'brandSecondary.600',
      },
      _loading: {
        bg: 'brandSecondary.600 !important',
      },
    },
    outlineSecondary: {
      bgColor: 'inherit',
      borderColor: 'brandSecondary.700',
      borderWidth: '1px',
      color: 'brandSecondary.700',
      _hover: {
        bg: 'brandSecondary.50',
      },
      _loading: {
        bg: 'brandSecondary.50 !important',
      },
    },
  },
  sizes: {
    lg: {
      fontSize: '16px',
      height: '48px',
    },
    sm: {
      fontSize: '14px',
      height: '40px',
    },
  },
};

export { button };
