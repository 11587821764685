import { extendTheme } from '@chakra-ui/react';

import { button } from './components/button';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'brandNeutral.700',
      },
    },
  },
  fonts: {
    heading: '"Space Grotesk", sans-serif',
    body: 'Inter, sans-serif',
    'space-grotesk': '"Space Grotesk", sans-serif',
  },
  colors: {
    brandPrimary: {
      50: '#E5E5FF',
      100: '#CCCCFF',
      200: '#9999FF',
      300: '#6666FF',
      400: '#3333FF',
      500: '#0000FF',
      600: '#0000CC',
      700: '#000099',
      800: '#000080',
      900: '#00004A',
      950: '#00001A',
    },
    brandSecondary: {
      50: '#FFFAE8',
      100: '#FFF3CC',
      200: '#FFE799',
      300: '#FFDB66',
      400: '#FFCF33',
      500: '#FEC200',
      600: '#CC9C00',
      700: '#B98D00',
      800: '#806100',
      900: '#4D3A00',
      950: '#191300',
    },
    brandWarning: {
      50: '#FEF8E6',
      100: '#FEF1CD',
      200: '#FDE39B',
      300: '#FBD46A',
      400: '#FAC638',
      500: '#F9B806',
      600: '#C79305',
      700: '#956E04',
      800: '#644A02',
      900: '#322501',
      950: '#191201',
    },
    overlayWhite: {
      10: 'rgba(255, 255, 255, 0.1)',
      20: 'rgba(255, 255, 255, 0.2)',
      30: 'rgba(255, 255, 255, 0.3)',
      40: 'rgba(255, 255, 255, 0.4)',
      60: 'rgba(255, 255, 255, 0.6)',
      80: 'rgba(255, 255, 255, 0.8)',
    },
    brandNeutral: {
      50: '#F1F2F3',
      100: '#E3E5E8',
      200: '#C8CBD0',
      300: '#ACB0B9',
      400: '#9196A1',
      500: '#757C8A',
      600: '#5E636E',
      700: '#464A53',
      800: '#2F3237',
      900: '#121416',
      950: '#0C0C0E',
    },
  },
  components: {
    Button: button,
  },
});

export { theme };
