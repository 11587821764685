const routesUrl = {
  index: '/',
  service: '/service',
  promo: '/promo',
  aboutUs: '/about-us',
  consultation: '/consultation',
  termsCondition: '/terms-condition',
  faq: '/faq',
  privacyPolicy: '/privacy-policy',
  article: '/article',
  articleDetail: '/article/detail/:id',
};

export { routesUrl };
