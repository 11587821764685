import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import type { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: 'id',
    fallbackLng: 'id',
    ns: [
      'common',
      'home_page',
      'why_choose_us',
      'promo_page',
      'about_us_page',
      'consultation_page',
      'terms_condition_page',
      'privacy_policy_page',
      'faq_page',
      'service_page',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
