import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PageNotFound } from '@/pages/404';

import { routesUrl } from './routesUrl';

const Index = lazy(() =>
  import('@/pages/Index').then((mod) => ({ default: mod.Index })),
);
const Service = lazy(() =>
  import('@/pages/Service').then((mod) => ({ default: mod.Service })),
);
const Promo = lazy(() =>
  import('@/pages/Promo').then((mod) => ({ default: mod.Promo })),
);
const AboutUs = lazy(() =>
  import('@/pages/AboutUs').then((mod) => ({ default: mod.AboutUs })),
);
const Consultation = lazy(() =>
  import('@/pages/Consultation').then((mod) => ({ default: mod.Consultation })),
);
const TermsCondition = lazy(() =>
  import('@/pages/TermsConditions').then((mod) => ({
    default: mod.TermsConditions,
  })),
);
const Faq = lazy(() =>
  import('@/pages/Faq').then((mod) => ({
    default: mod.Faq,
  })),
);
const PrivacyPolicy = lazy(() =>
  import('@/pages/PrivacyPolicy').then((mod) => ({
    default: mod.PrivacyPolicy,
  })),
);
const Article = lazy(() =>
  import('@/pages/Article').then((mod) => ({
    default: mod.Article,
  })),
);
const ArticleDetail = lazy(() =>
  import('@/pages/ArticleDetail').then((mod) => ({
    default: mod.ArticleDetailPage,
  })),
);

function Router() {
  return (
    <Routes>
      <Route path={routesUrl.index} element={<Index />} />
      <Route path={routesUrl.service} element={<Service />} />
      <Route path={routesUrl.promo} element={<Promo />} />
      <Route path={routesUrl.aboutUs} element={<AboutUs />} />
      <Route path={routesUrl.consultation} element={<Consultation />} />
      <Route path={routesUrl.termsCondition} element={<TermsCondition />} />
      <Route path={routesUrl.faq} element={<Faq />} />
      <Route path={routesUrl.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={routesUrl.article} element={<Article />} />
      <Route path={routesUrl.articleDetail} element={<ArticleDetail />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}

export { routesUrl, Router };
